import React from 'react';
import Navigation from "../components/Navigation";

const About = () => {
    return (
        <div>
            <Navigation/>
            <h1>A proops</h1>
            <br/>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A alias amet architecto asperiores atque deleniti dolore dolorem dolores fugiat hic illum inventore iure libero nisi nulla numquam obcaecati officia praesentium quaerat quisquam reiciendis sapiente vero vitae, voluptatem voluptates. Ab asperiores consectetur culpa cum cumque deleniti dicta dolore doloremque dolores eligendi eos fugit illum inventore ipsam laborum, maiores minima molestias obcaecati odio odit omnis praesentium provident sapiente tenetur ullam. Accusantium ad assumenda aut autem, consequuntur corporis cupiditate dolore dolorem fuga illum impedit inventore natus, provident quia quisquam quo totam vel veniam veritatis voluptatibus. Amet deserunt dolores nesciunt provident ullam? Ipsa, tenetur.</p>
            <br/>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores at beatae corporis distinctio dolorum fugiat harum, inventore ipsum iure, magnam magni maxime modi neque non nostrum repellat repudiandae sint sit tenetur, voluptas. Corporis error harum laudantium odio praesentium sint soluta sunt! Adipisci alias aliquid dolore dolores earum excepturi facilis iste magni, molestiae nisi nobis obcaecati officiis perferendis provident, quidem quisquam, rem similique tempora tempore tenetur veritatis vitae voluptas. Ad at consequatur dicta doloribus eveniet, laboriosam neque nostrum quam temporibus? Asperiores deserunt, earum, explicabo facilis maiores necessitatibus nemo neque, possimus qui quis reprehenderit sapiente similique sint ullam unde? Amet, beatae, eum.</p>
        </div>
    );
};

export default About;