import React from 'react';
import {NavLink} from "react-router-dom";

const Navigation = () => {
    return (
        <div class="navigation">
            <ul>
                <NavLink to="/">
                    <li>accueil</li>
                </NavLink>
                <NavLink to="/about">
                    <li>a proops</li>
                </NavLink>
            </ul>
        </div>
    );
};

export default Navigation;