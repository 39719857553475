import React from 'react';
import logo from "../assets/mylogo.svg";

import Navigation from "../components/Navigation";

const Home = () => {
    return (
        <div className="App">

            <header className="fixed-top">
                <div className="mt-4 navbar-div">
                    <img src={logo} alt="Logo" height="30"></img>
                        <a className="btn btn-sm btn-outline-secondary navbar-button" href="#">Experience</a>
                        <a className="btn btn-sm btn-outline-secondary navbar-button" href="#">Education</a>
                        <a className="btn btn-sm btn-outline-secondary navbar-button" href="#">Projets</a>
                        <a className="btn btn-sm btn-outline-secondary navbar-button" href="#">Loisirs</a>
                        <a className="btn btn-sm btn-outline-secondary navbar-button right-element"
                           href="mailto:example@example.com">Email</a>
                </div>
            </header>

            <div className="header-back"></div>

            <section className="background">
                <div className="free-space">bla</div>
            </section>


            <Navigation/>
            <h1>HELLO WORLD !</h1>
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p>
                    Edit <code>src/App.js</code> and save to reload.
                </p>
                <a
                    className="App-link"
                    href="https://reactjs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Learn React
                </a>
            </header>
            <h1 className="h1-dark">Bonjour j'ajoute un truc</h1>
            <button type="button" className="btn btn-primary">Primary</button>
        </div>
    );
};

export default Home;