// dependencies
import { BrowserRouter, Routes, Route} from "react-router-dom"

//components
import Home from "./pages/Home";
import About from "./pages/About";

function App() {
  return (
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/about" element={<About/>} />

            {/* * regex, pour tout url non déclaré */}
            <Route path="*" element={<Home/>} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
